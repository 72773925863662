import axios from "axios";
import SideNavbar from "../Components/SideNavabar";
import { IoMdHome } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { IoIosAddCircle } from "react-icons/io";
import { toast } from "react-toastify";
import { useEffect, useRef, useState } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import SpinnerComponent from "../Components/SpinnerComponent";
import Notifications from "../Components/Notifications/notification";
import { browserName, isMobileOnly } from "react-device-detect";
import moment from "moment";
import { Mixpanel } from "../Services/mixpanel";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function TPODashboard() {
  const navigate = useNavigate();
  const [loading, set_loading] = useState(false);
  const [total_applications, set_total_applications] = useState(0);
  const [total_jobs, set_total_jobs] = useState(0);
  const [shortlisted_application, set_shortlisted_application] = useState(0);
  const [graph_data, set_graph_data]: any = useState([]);
  const [application_data, set_application_data]: any = useState([]);
  const [notification, setNotification]: any = useState(false);
  const menuRef: any = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (notification && !menuRef.current.contains(event.target)) {
        setNotification(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [notification]);

  const handleNotifications = () => {
    Mixpanel.track("notifications_clicked", {
      date: moment().format(),
      browser: browserName,
      mobile_device: isMobileOnly,
      distinct_id: localStorage.getItem("tpo_id"),
      tpo_id: localStorage.getItem("tpo_id"),
    });
    setNotification(!notification);
  };

  const get_tpo_dashboard = async () => {
    set_loading(true);
    axios
      .get(`https://api.beep.community/tpo/tpo_dashboard`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tpo_token"),
        },
      })
      .then(({ data }) => {
        set_loading(false);
        set_total_applications(data.total_applications);
        set_total_jobs(data.total_jobs);
        set_shortlisted_application(data.shortlisted_application);
        set_graph_data([
          data.invited_users,
          data.profile_completed_users,
          data.first_time_application,
        ]);

        set_application_data([
          data.total_applications,
          data.shortlisted_application,
          data.rejected_application,
          data.selected_application,
        ]);
      })
      .catch((err) => {
        console.log(err);
        set_loading(false);
        toast.error(
          `Failed to get dashboard, Please try again ${
            err?.response?.data?.error ? err.response.data.error : ""
          }`
        );
      });
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          display: false,
        },
        ticks: {
          stepSize: 1,
          min: 1,
          max: 10,
          callback: function (value: any, index: any, values: any) {
            return value;
          },
        },
      },
    },
  };

  const labels = ["Invited Students", "Onboarded Students", "Students Applied"];

  const new_labels = [
    "Number of Application",
    "Shortlisted Application",
    "Rejected Applications",
    "Selected Applications",
  ];

  const data = {
    labels,
    datasets: [
      {
        data: graph_data,
        label: "Students",
        backgroundColor: [
          "rgba(255, 159, 64, 0.2)",
          "rgba(255, 205, 86, 0.2)",
          "rgba(153, 102, 255, 0.2)",
        ],
        borderColor: [
          "rgb(255, 159, 64)",
          "rgb(255, 205, 86)",
          "rgb(153, 102, 255)",
        ],
        borderRadius: [10, 10, 10, 10, 10, 10],
        borderWidth: 1,
      },
    ],
  };

  const data_applications = {
    labels: new_labels,
    datasets: [
      {
        data: application_data,
        label: "Students",
        backgroundColor: [
          "rgba(153, 102, 255, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 99, 132, 0.2)",
          "rgba(75, 192, 192, 0.2)",
        ],
        borderColor: [
          "rgb(153, 102, 255)",
          "rgb(54, 162, 235)",
          "rgb(255, 99, 132)",
          "rgb(75, 192, 192)",
        ],
        borderRadius: [10, 10, 10, 10, 10, 10],
        borderWidth: 1,
      },
    ],
  };

  useEffect(() => {
    get_tpo_dashboard();
  }, []);

  return (
    <div className="flex">
      <div className="w-1/6">
        <SideNavbar page_name="Home" />
      </div>
      <div className="w-5/6 p-4 h-screen overflow-x-scroll bg-[#F2F4F5]">
        <div className=" flex justify-between items-center relative">
          <div>
            <div className="flex m-2 items-center">
              <IoMdHome size={25} />
              <p className="font-bold ml-2 text-2xl">Home</p>
            </div>
            <p className="font-light text-sm  ml-2 -mt-2">
              View all your important data in one place
            </p>
          </div>
          <div
            onClick={handleNotifications}
            className="rounded-full border-2 bg-white  w-12 h-12 flex justify-center items-center cursor-pointer relative"
          >
            <svg
              className="relative"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M17.3282 13.7453C16.8946 12.9984 16.2501 10.8852 16.2501 8.125C16.2501 6.4674 15.5916 4.87769 14.4195 3.70558C13.2474 2.53348 11.6577 1.875 10.0001 1.875C8.34247 1.875 6.75276 2.53348 5.58065 3.70558C4.40855 4.87769 3.75007 6.4674 3.75007 8.125C3.75007 10.8859 3.10476 12.9984 2.67116 13.7453C2.56044 13.9352 2.50174 14.1509 2.50098 14.3707C2.50023 14.5905 2.55745 14.8066 2.66687 14.9973C2.77629 15.1879 2.93404 15.3463 3.12422 15.4565C3.31439 15.5667 3.53027 15.6248 3.75007 15.625H6.93835C7.08255 16.3306 7.46603 16.9647 8.02392 17.4201C8.58182 17.8756 9.2799 18.1243 10.0001 18.1243C10.7202 18.1243 11.4183 17.8756 11.9762 17.4201C12.5341 16.9647 12.9176 16.3306 13.0618 15.625H16.2501C16.4698 15.6247 16.6856 15.5665 16.8757 15.4562C17.0657 15.346 17.2234 15.1875 17.3327 14.9969C17.442 14.8063 17.4992 14.5903 17.4984 14.3705C17.4976 14.1508 17.4389 13.9351 17.3282 13.7453ZM10.0001 16.875C9.61243 16.8749 9.23435 16.7546 8.91788 16.5308C8.60141 16.3069 8.3621 15.9905 8.23288 15.625H11.7673C11.638 15.9905 11.3987 16.3069 11.0823 16.5308C10.7658 16.7546 10.3877 16.8749 10.0001 16.875Z"
                fill="#002032"
              />
            </svg>
            <svg
              className="absolute ml-2 mb-2"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <g filter="url(#filter0_d_9172_77410)">
                <circle cx="10" cy="9" r="4" fill="#63D5AC" />
                <circle cx="10" cy="9" r="3.5" stroke="#16C083" />
              </g>
              <defs>
                <filter
                  id="filter0_d_9172_77410"
                  x="0.8"
                  y="0.8"
                  width="18.4"
                  height="18.4"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dy="1" />
                  <feGaussianBlur stdDeviation="2.6" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.270588 0 0 0 0 0.803922 0 0 0 0 0.611765 0 0 0 1 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_9172_77410"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_9172_77410"
                    result="shape"
                  />
                </filter>
              </defs>
            </svg>
          </div>
          {notification && <Notifications menuRef={menuRef} />}
        </div>
        <div className="flex mt-4 grid-cols-4 gap-2">
          <div
            className="flex w-1/4 col-span-1 p-6 rounded-lg bg-[#002032] text-white justify-center items-center cursor-pointer"
            onClick={() => {
              navigate("/batches");
            }}
          >
            <IoIosAddCircle size={24} />
            <p className="ml-2 font-bold text-lg">Add new Students</p>
          </div>
          <div className="flex  w-1/4 col-span-1 p-6 rounded-lg justify-center items-center bg-white">
            <p className="w-1/2 grid justify-items-end text-sm font-light text-right">
              Total Applications
            </p>
            <p className="w-1/2 grid justify-items-start ml-2 font-bold text-5xl">
              {total_applications}
            </p>
          </div>
          <div className="flex w-1/4 col-span-1 p-6 rounded-lg justify-center items-center bg-white">
            <p className="w-1/2 grid justify-items-end text-sm font-light text-right">
              Shortlisted Applications
            </p>
            <p className="w-1/2 grid justify-items-start ml-2 font-bold text-5xl">
              {shortlisted_application}
            </p>
          </div>
          <div className="flex w-1/4 col-span-1 p-6 rounded-lg justify-center items-center bg-white">
            <p className="w-1/2 grid justify-items-end text-sm font-light text-right">
              Total Active Jobs
            </p>
            <p className="w-1/2 grid justify-items-start ml-2 font-bold text-5xl">
              {total_jobs}
            </p>
          </div>
        </div>
        <div className="flex flex-col ">
          <div className="h-full w-full mt-4 bg-white rounded-lg p-2">
            <p className="font-bold text-lg mb-4 ml-4 text-[#002032]">
              Student Insights
            </p>
            <Bar data={data} options={options} className="w-full h-full" />
          </div>
          <div className="h-full w-full mt-4 bg-white rounded-lg p-2">
            <p className="font-bold text-lg mb-4 ml-4 text-[#002032]">
              Application Insights
            </p>
            <Bar
              data={data_applications}
              options={options}
              className="w-full h-full"
            />
          </div>
        </div>
      </div>
      {loading ? <SpinnerComponent /> : null}
    </div>
  );
}

export default TPODashboard;
