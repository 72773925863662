import axios from "axios";
import SideNavbar from "../Components/SideNavabar";
import { IoSettings } from "react-icons/io5";
import { useState } from "react";
import { toast } from "react-toastify";
import { Button, Label, TextInput } from "flowbite-react";
import SpinnerComponent from "../Components/SpinnerComponent";

function Settings() {
  const [pass, set_pass] = useState("");
  const [repass, set_repass] = useState("");
  const [loading, set_loading] = useState(false);
  const change_password = async (event: any) => {
    event.preventDefault();
    if (pass !== repass) {
      toast.error("Passwords do not match");
    } else {
      set_loading(true);
      axios
        .post(
          `https://api.beep.community/tpo/changepassword`,
          { password: pass },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("tpo_token"),
            },
          }
        )
        .then(({ data }) => {
          set_loading(false);
          toast.success("Password reset success.");
        })
        .catch((err) => {
          set_loading(false);
          toast.error("Failed to reset password. Please try again later");
        });
    }
  };
  return (
    <div className="flex">
      <div className="w-1/6">
        <SideNavbar page_name="Settings" />
      </div>
      <div className="w-5/6 p-4 h-screen overflow-x-scroll bg-[#F2F4F5]">
        <div className="flex m-2 items-center">
          <IoSettings size={25} />
          <p className="font-bold ml-2 text-2xl">Settings</p>
        </div>
        <div className="bg-white rounded-lg mt-4 p-2 w-1/4 flex flex-col items-center">
          <form onSubmit={change_password}>
            <div className="mt-2">
              <Label htmlFor="password" value="Password" />
              <TextInput
                id="password"
                type="password"
                placeholder="Enter your Password"
                value={pass}
                onChange={(event) => {
                  set_pass(event.target.value);
                }}
                required
              />
            </div>
            <div className="mt-2">
              <Label htmlFor="re_password" value="Re-enter Password" />
              <TextInput
                id="password"
                type="password"
                placeholder="Enter renter Password"
                value={repass}
                onChange={(event) => {
                  set_repass(event.target.value);
                }}
                required
              />
            </div>
            <div className="flex justify-center">
              <Button
                color="#FFDA46"
                className="mt-2 bg-[#FFDA46] text-black"
                type="submit"
              >
                Change Password
              </Button>
            </div>
          </form>
        </div>
      </div>
      {loading?<SpinnerComponent/>:null}

    </div>
  );
}

export default Settings;
