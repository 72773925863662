import axios from "axios";
import { verify } from "crypto";
import { Button, Label, TextInput } from "flowbite-react";
import { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import SpinnerComponent from "../Components/SpinnerComponent";
import BeepLogo from "../Static/Images/BeepLogo.svg";
import { IoArrowBackOutline } from "react-icons/io5";

function ForgotPassword() {
  const [email, set_email] = useState("");
  const [password, set_password] = useState("");
  const [loading, set_loading] = useState(false);
  const [repass, set_repass] = useState("");
  const [state, set_state] = useState("email");
  const [otp, set_otp] = useState("");

  const navigate = useNavigate();

  const send_verification_otp = async () => {
    set_loading(true);
    axios
      .post(`https://api.beep.community/tpo/resetOTP`, { email: email })
      .then(({ data }) => {
        set_state("OTP");
        set_loading(false);
      })
      .catch((err) => {
        set_loading(false);
        toast.error(
          `Failed to send reset OTP: ${
            err?.response?.data?.error ? err.response.data.error : ""
          }`
        );
      });
  };

  const verify_otp = async () => {
    set_loading(true);
    axios
      .post(`https://api.beep.community/tpo/verifyOTP`, { email: email, otp: otp })
      .then(({ data }) => {
        set_state("OTP Verified");
        set_loading(false);
      })
      .catch((err) => {
        set_loading(false);
        toast.error(
          `Failed to verify OTP: ${
            err?.response?.data?.error ? err.response.data.error : ""
          }`
        );
      });
  };

  const change_password = async () => {
    if (password !== repass) {
      toast.error("Passwords donot match.");
    } else {
      set_loading(true);
      axios
        .post(`https://api.beep.community/tpo/resetPassword`, {
          email: email,
          otp: otp,
          password: password,
        })
        .then(({ data }) => {
          navigate("/");
          set_loading(false);
          toast.success(
            "Password changed successfully, use your new password to login"
          );
        })
        .catch((err) => {
          set_loading(false);
          toast.error(
            `Failed to change password: ${
              err?.response?.data?.error ? err.response.data.error : ""
            }`
          );
        });
    }
  };

  const handle_submit = async (event: any) => {
    event.preventDefault();
    if (state === "email") {
      send_verification_otp();
    } else if (state === "OTP") {
      verify_otp();
    } else if (state === "OTP Verified") {
      change_password();
    }
  };

  return (
    <div className="flex h-screen justify-center items-center">
      <div className="flex flex-col w-1/3 justify-center">
        <img src={BeepLogo} className="h-32 mt-2 mb-2" />
        <form className="flex flex-col items-center" onSubmit={handle_submit}>
          <div className="flex w-2/3 mt-4 text-xs">
            <a href="/" className="flex justify-center">
              <IoArrowBackOutline />
              <p className="ml-1">Back to Login</p>
            </a>
          </div>
          {state === "OTP" ? (
            <div className="flex items-center text-center w-2/3 mt-2 rounded-lg p-4 bg-[#F2F4F5] text-xs">
              OTP has been sent to your registered email
            </div>
          ) : null}

          <div className="w-2/3 mt-2">
            <Label htmlFor="email" value="Email" />
            <TextInput
              id="email"
              type="email"
              placeholder="Enter your Email"
              value={email}
              onChange={(event) => {
                set_email(event.target.value);
              }}
              disabled={state !== "email"}
              required={state == "email"}
            />
          </div>
          {state === "OTP" ? (
            <div className="w-2/3 mt-2">
              <Label htmlFor="otp" value="OTP" />
              <TextInput
                id="otp"
                type="number"
                placeholder="0 0 0 0 0 0"
                value={otp}
                onChange={(event) => {
                  set_otp(event.target.value);
                }}
                required={state === "OTP" ? true : false}
              />
            </div>
          ) : null}
          {state === "OTP Verified" ? (
            <>
              <div className="w-2/3 mt-2">
                <Label htmlFor="password" value="Password" />
                <TextInput
                  id="password"
                  type="password"
                  placeholder="Enter your Password"
                  value={password}
                  onChange={(event) => {
                    set_password(event.target.value);
                  }}
                  required
                />
              </div>
              <div className="w-2/3 mt-2">
                <Label htmlFor="password" value="Password" />
                <TextInput
                  id="password"
                  type="password"
                  placeholder="Re-enter your Password"
                  value={repass}
                  onChange={(event) => {
                    set_repass(event.target.value);
                  }}
                  required
                />
              </div>
            </>
          ) : null}

          <Button
            color="#FFDA46"
            className="mt-2 w-2/3 bg-[#FFDA46] text-black"
            type="submit"
            disabled={loading}
          >
            {state === "email" ? <>Send OTP</> : null}
            {state === "OTP" ? <>Verify OTP</> : null}
            {state === "OTP Verified" ? <>Change Password</> : null}
          </Button>
        </form>
      </div>
      {loading ? <SpinnerComponent /> : null}
    </div>
  );
}

export default ForgotPassword;
