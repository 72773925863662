import { IoMdHome } from "react-icons/io";
import { FaBriefcase } from "react-icons/fa6";
import { IoSettings } from "react-icons/io5";
import { FaGripHorizontal } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { RiLogoutBoxFill } from "react-icons/ri";
import { FaBook } from "react-icons/fa6";
import { Mixpanel } from "../Services/mixpanel";
import moment from "moment";
import { browserName, isMobileOnly } from "react-device-detect";

function SideNavbar(props: any) {
  const { page_name } = props;
  const navigate = useNavigate();
  let logo: any = localStorage.getItem("campus_logo");
  return (
    <div className="h-screen ">
      <div className="flex mt-4 mr-2 ml-2 items-center justify-center">
        <img src={logo} className="h-16" />
        <div className="ml-2">
          <p className="font-bold">{localStorage.getItem("campus_name")}</p>
          <p className="font-light text-sm">
            {localStorage.getItem("campus_city")}
          </p>
        </div>
      </div>
      <hr className="h-px my-8 bg-gray-200 border-1 dark:bg-gray-700 mr-2 ml-2"></hr>
      <div id="navigation" className="mt-6">
        {page_name === "Home" ? (
          <div className="flex bg-[#FFDA46] mr-4 ml-4 mt-1 pt-2 pb-2 pr-6 pl-6 rounded-md cursor-pointer items-center">
            <IoMdHome size={20} />
            <p className="font-bold text-large ml-2">Home</p>
          </div>
        ) : (
          <div
            className="flex mr-4 ml-4 pt-2 pb-2 text-[#667984] mt-1 pr-6 pl-6 rounded-md cursor-pointer items-center"
            onClick={() => {
              navigate("/home");
            }}
          >
            <IoMdHome size={18} />
            <p className="font-medium ml-2">Home</p>
          </div>
        )}
        {page_name === "Batches" ? (
          <div className="flex bg-[#FFDA46] mr-4 ml-4 mt-1 pt-2 pb-2 pr-6 pl-6 rounded-md cursor-pointer items-center">
            <FaBook size={20} />
            <p className="font-bold text-large ml-2">Students</p>
          </div>
        ) : (
          <div
            className="flex mr-4 ml-4 pt-2 pb-2 mt-1 pr-6 pl-6 text-[#667984] rounded-md cursor-pointer items-center"
            onClick={() => {
              navigate("/batches");
            }}
          >
            <FaBook size={18} />
            <p className="font-medium ml-2">Students</p>
          </div>
        )}
        {page_name === "Jobs" ? (
          <div className="flex bg-[#FFDA46] mr-4 ml-4 mt-1 pt-2 pb-2 pr-6 pl-6 rounded-md cursor-pointer items-center">
            <FaBriefcase size={20} />
            <p className="font-bold text-large ml-2">Jobs</p>
          </div>
        ) : (
          <div
            className="flex mr-4 ml-4 pt-2 pb-2 mt-1 pr-6 pl-6 text-[#667984] rounded-md cursor-pointer items-center"
            onClick={() => {
              navigate("/jobs");
            }}
          >
            <FaBriefcase size={18} />
            <p className="font-medium ml-2">Jobs</p>
          </div>
        )}
        {page_name === "Settings" ? (
          <div className="flex bg-[#FFDA46] mr-4 ml-4 mt-1 pt-2 pb-2 pr-6 pl-6 rounded-md cursor-pointer items-center">
            <IoSettings size={20} />
            <p className="font-bold text-large ml-2">Settings</p>
          </div>
        ) : (
          <div
            className="flex mr-4 ml-4 pt-2 pb-2 mt-1 pr-6 pl-6 text-[#667984] rounded-md cursor-pointer items-center"
            onClick={() => {
              navigate("/settings");
            }}
          >
            <IoSettings size={18} />
            <p className="font-medium ml-2">Settings</p>
          </div>
        )}
        <div
          className="flex mr-4 ml-4 pt-2 pb-2 mt-1 pr-6 pl-6 text-white bg-red-700 rounded-md cursor-pointer items-center"
          onClick={() => {
            Mixpanel.track("logout_clicked", {
              date: moment().format(),
              browser: browserName,
              mobile_device: isMobileOnly,
              distinct_id: localStorage.getItem("tpo_id"),
              tpo_id: localStorage.getItem("tpo_id"),
              campus_alias: localStorage.getItem("campus_alias"),
              campus_name: localStorage.getItem("campus_name"),
              campus_city: localStorage.getItem("campus_city"),
            });
            localStorage.clear();
            navigate("/");
          }}
        >
          <RiLogoutBoxFill size={18} />
          <p className="font-medium ml-2">Logout</p>
        </div>
      </div>
    </div>
  );
}
export default SideNavbar;
