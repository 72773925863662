import "./App.css";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { clarity } from "react-microsoft-clarity";

//Pages Import
import Login from "./Pages/Login";
import TPODashboard from "./Pages/TPODashboard";
import Jobs from "./Pages/Jobs";
import Settings from "./Pages/Settings";
import Batches from "./Pages/Batches";
import JobDetails from "./Pages/JobDetails";
import ForgotPassword from "./Pages/ForgotPassword";
import { useEffect } from "react";

function App() {
  useEffect(() => {
    clarity.init("l7oxqik788");
  }, []);

  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/home" element={<TPODashboard />} />
          <Route path="/jobs" element={<Jobs />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/batches" element={<Batches />} />
          <Route path="/job_deatils/:id" element={<JobDetails />} />
          <Route path="/forgotpassword" element={<ForgotPassword />} />
        </Routes>
      </BrowserRouter>
      <ToastContainer />
    </div>
  );
}

export default App;
