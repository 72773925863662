import axios from "axios";
import { Button, Label, TextInput } from "flowbite-react";
import { useState } from "react";
import BeepLogo from "../Static/Images/BeepLogo.svg";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import SpinnerComponent from "../Components/SpinnerComponent";
import { Mixpanel } from "../Services/mixpanel";
import moment from "moment";
import { browserName, isMobileOnly } from "react-device-detect";
import { v4 as uuidV4 } from "uuid";
function Login() {
  const [email, set_email] = useState("");
  const [password, set_password] = useState("");
  const [loading, set_loading] = useState(false);

  const navigate = useNavigate();
  const handleClickForgetPass = () => {
    Mixpanel.track("forgot_password_clicked", {
      browser: browserName,
      mobile_device: isMobileOnly,
      distinct_id: uuidV4(),
    });
  };
  const login = async (event: any) => {
    event.preventDefault();
    const BASE_SERVER_URL = `https://api.beep.community`;

    Mixpanel.track("sign_in_successful_clicked", {
      date: moment().format(),
      browser: browserName,
      mobile_device: isMobileOnly,
      distinct_id: uuidV4(),
    });
    set_loading(true);
    axios
      .post(`${BASE_SERVER_URL}/tpo/login`, {
        email: email,
        password: password,
      })
      .then(({ data }) => {
        set_loading(false);
        localStorage.setItem("tpo_token", data.token);
        localStorage.setItem("campus_name", data.campus_info.campus_name);
        localStorage.setItem("campus_logo", data.logo);
        localStorage.setItem("campus_city", data.campus_info.city);
        localStorage.setItem("campus_alias", data.campus_info.campus_alias);
        localStorage.setItem("tpo_id", data.tpo_id);
        Mixpanel.track("user_sign_in_successful", {
          date: moment().format(),
          email,
          browser: browserName,
          mobile_device: isMobileOnly,
          distinct_id: data.tpo_id,
          tpo_id: data.tpo_id,
          campus_name: data.campus_info.campus_name,
          campus_alias: data.campus_info.campus_alias,
          campus_city: data.campus_info.city,
          isUserSignIn: true,
        });
        navigate("/home");
      })
      .catch((err) => {
        set_loading(false);
        console.log(err);
        toast.error(
          `Failed to Login ${
            err.response.data.error ? err.response.data.error : ""
          }`
        );
        Mixpanel.track("user_sign_in_failed", {
          email: email,
          browser: browserName,
          mobile_device: isMobileOnly,
          errorMessage: err.response.data.error
            ? err.response.data.error
            : "Unknown Error",
          isUserSignIn: false,
        });
      });
  };
  return (
    <div className="flex h-screen justify-center items-center">
      <div className="flex flex-col w-1/3 justify-center">
        <img src={BeepLogo} className="h-32 mt-2 mb-2" />
        <form className="flex flex-col items-center" onSubmit={login}>
          <div className="w-2/3 mt-2">
            <Label htmlFor="email" value="Email" />
            <TextInput
              id="email"
              type="email"
              placeholder="Enter your Email"
              value={email}
              onChange={(event) => {
                set_email(event.target.value);
              }}
              required
            />
          </div>
          <div className="w-2/3 mt-2">
            <Label htmlFor="password" value="Password" />
            <TextInput
              id="password"
              type="password"
              placeholder="Enter your Password"
              value={password}
              onChange={(event) => {
                set_password(event.target.value);
              }}
              required
            />
          </div>
          <div className="w-2/3 grid text-xs justify-items-end mt-2 text-blue-700">
            <a onClick={handleClickForgetPass} href="/forgotpassword">
              Forgot Password?
            </a>
          </div>
          <Button
            color="#FFDA46"
            className="mt-2 w-2/3 bg-[#FFDA46] text-black"
            type="submit"
          >
            Sign In
          </Button>
        </form>
      </div>
      {loading ? <SpinnerComponent /> : null}
    </div>
  );
}

export default Login;
