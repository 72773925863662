import { Spinner } from "flowbite-react";

function SpinnerComponent() {
  return (
      <div className="flex fixed left-1/2 top-1/2 flex-col items-center justify-center w-10 h-10">
        <Spinner size="xl" />
        <p className="font-bold text-lg mt-1">Loading</p>
      </div>
  );
}

export default SpinnerComponent;
