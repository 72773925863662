import React from "react";
interface FilterBoxProps {
    cancel : any 
    filterStatus :any
    setfilterStatus : any
    handleGetStudents:any
    setToggeler : any
    setFilterBox:any
}
function FilterBox({cancel,setfilterStatus ,filterStatus,handleGetStudents,setToggeler,setFilterBox }:FilterBoxProps) {
  const handleBothStudentAndToggle =()=>{
    handleGetStudents()
    setToggeler(true)
    setFilterBox(false)
  }
  return (
    <div className="w-[193px] h-[156px] z-20 py-1.5 bg-white rounded-xl shadow border border-gray-200 flex-col justify-start items-start inline-flex absolute top-16">
      <div className="self-stretch px-2.5 justify-end items-center gap-2.5 inline-flex">
        <div className="w-3 h-3 relative" />
      </div>
      <div className="self-stretch px-2.5 py-2 border-b justify-start items-center gap-2 inline-flex">
        <div onClick={()=>{setfilterStatus("Invited");setToggeler(false)}} className={` border-2 ${filterStatus ==="Invited" ? "border-gray-600" : "border-gray-300"} justify-start items-center flex rounded-full`}>
          <div className="w-4 h-4 relative" />
        </div>
        <div className="grow shrink basis-0 text-slate-500 text-sm font-medium font-satoshi leading-tight">
          Invited
        </div>
        <div className="w-1 h-7 relative" />
      </div>
      <div className="self-stretch px-2.5 py-2 border-b border-gray-200 justify-start items-center gap-2 inline-flex">
        <div onClick={()=>{setfilterStatus("Onboarded");setToggeler(false)}} className={`justify-start ${filterStatus ==="Onboarded" ? "border-gray-600" : "border-gray-300"}  items-center flex border-2 rounded-full`}>
          <div className="w-4 h-4 relative" />
        </div>
        <div className="grow shrink basis-0 text-slate-500 text-sm font-medium font-satoshi leading-tight">
          Onboarded
        </div>
        <div className="w-1 h-7 relative" />
      </div>
      <div className="w-[193px] px-2.5 py-2 justify-center items-center gap-1 inline-flex">
        <div className="grow shrink basis-0 h-7 justify-center items-center gap-1 flex">
          <div className="px-[9px] py-[5px] rounded-md justify-center items-center gap-2.5 flex">
            <div onClick={cancel} className="cursor-pointer text-center text-teal-950 text-sm font-medium font-satoshi leading-[17.94px]">
              Cancel
            </div>
          </div>
          <div className="cursor-pointer px-[9px] py-[5px] bg-teal-950 rounded-lg justify-center items-center gap-2.5 flex">
            <div onClick={handleBothStudentAndToggle } className=" text-center text-white text-sm font-medium font-satoshi leading-[17.94px]">
              Apply Filter
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FilterBox;
