import React, { useEffect, useState } from "react";
import {
  Spinner,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow,
} from "flowbite-react";
import axios from "axios";
import moment from "moment";

function ViewStatus({ onClose, candidateInfoId }: any) {
  const [studentData, setStudentData]: any = useState([]);
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const studentInfo = async (userId: string) => {
    try {
      setLoading(true);
      const BASE_SERVER_URL = `https://api.beep.community`;
      const response = await axios.get(
        `${BASE_SERVER_URL}/tpo/student_info/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("tpo_token")}`,
          },
        }
      );

      setLoading(false);
      setStudentData(response.data);
    } catch (error) {
      console.log(error);
      setLoading(false);
      setIsError(true);
    }
  };

  useEffect(() => {
    if (candidateInfoId) studentInfo(candidateInfoId);
  }, [candidateInfoId]);

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
      <div className="relative bg-white rounded-lg p-8 max-h-96 overflow-y-auto scroll-smooth overflow-hidden">
        <button
          className="absolute right-2 top-2 text-gray-500 hover:text-gray-700"
          onClick={onClose}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
        <Table>
          <TableHead>
            <TableHeadCell>Company</TableHeadCell>
            <TableHeadCell>Position</TableHeadCell>
            <TableHeadCell>Application Date</TableHeadCell>
            <TableHeadCell>Status</TableHeadCell>
          </TableHead>
          {studentData.success ? (
            <TableBody>
              {studentData?.candidateInfo?.map(
                (application: any, index: number) => (
                  <TableRow key={index + 1}>
                    <TableCell>
                      {application?.company_info?.registeredName}
                    </TableCell>
                    <TableCell>{application?.job_info?.job_profile}</TableCell>
                    <TableCell>
                      {moment(
                        application?.application_status?.timeline?.Applied_1
                      ).format("DD-MM-YYYY")}
                    </TableCell>
                    <TableCell>{application?.app_status}</TableCell>
                  </TableRow>
                )
              )}
            </TableBody>
          ) : (
            <div className="flex justify-center items-center h-auto">
              <p className="mt-4 mb-4">No Applications Found</p>
            </div>
          )}
        </Table>
        {loading && (
          <div className="text-center">
            <Spinner aria-label="Center-aligned spinner example" />
          </div>
        )}
      </div>
    </div>
  );
}

export default ViewStatus;
