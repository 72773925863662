import axios from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import SideNavbar from "../Components/SideNavabar";
import { FaBriefcase } from "react-icons/fa6";
import {
  Button,
  Table,
  TableCell,
  TableHeadCell,
  TableRow,
} from "flowbite-react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import SpinnerComponent from "../Components/SpinnerComponent";
import { Mixpanel } from "../Services/mixpanel";
import { browserName, isMobileOnly } from "react-device-detect";

function Jobs() {
  const [loading, set_loading] = useState(false);
  const [jobs, set_jobs]: any = useState([]);
  const [has_next, set_has_next] = useState(false);
  const [latest_count, set_latest_count] = useState(0);
  const [url_prefix, set_url_prefix] = useState("");
  const [searchWord, setSearchWord] = useState("");
  const [searchJobs, setSearchJobs]: any = useState([]);
  const navigate = useNavigate();
  const BASE_SERVER_URL = `https://api.beep.community`;

  const handleSearch = (e: any) => {
    const newWord = e.target.value;
    setSearchWord(newWord);
  };
  const handleClickMixpanel = () => {
    const mixpanelObj: any = {
      date: moment().format(),
      browser: browserName,
      mobile_device: isMobileOnly,
      distinct_id: localStorage.getItem("tpo_id"),
      tpo_id: localStorage.getItem("tpo_id"),
    };

    Mixpanel.track("job_search_clicked", mixpanelObj);
  };
  const get_jobs = async (skip: number, limit: number) => {
    set_loading(true);
    const searchBody: any = {};
    if (searchWord !== "") {
      searchBody["search"] = searchWord;
    }
    axios
      .post(
        `${BASE_SERVER_URL}/tpo/jobs?skip=${skip}&limit=${limit}`,
        searchBody,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("tpo_token"),
          },
        }
      )
      .then(({ data }) => {
        set_loading(false);
        if (searchWord !== "") {
          setSearchJobs([...searchJobs, ...data.jobs]);
          set_jobs([]);
        } else {
          set_jobs([...jobs, ...data.jobs]);
          setSearchJobs([]);
        }
        set_has_next(data.has_next);
        if (skip === 0) {
          set_latest_count(data.latest_jobs_count);
          set_url_prefix(data.url_prefix);
        }
      })
      .catch((err) => {
        set_loading(false);
        toast.error(
          `Failed to get jobs, Please try again ${
            err?.response?.data?.error ? err.response.data.error : ""
          }`
        );
      });
  };

  useEffect(() => {
    let timeOutId: any;

    const debounceSearch = () => {
      clearTimeout(timeOutId);
      timeOutId = setTimeout(() => {
        get_jobs(0, 30);
      }, 1000);
    };
    debounceSearch();
    return () => clearTimeout(timeOutId);
  }, [searchWord]);

  return (
    <div className="flex">
      <div className="w-1/6">
        <SideNavbar page_name="Jobs" />
      </div>
      <div className="w-5/6 p-4 h-screen overflow-x-scroll bg-[#F2F4F5]">
        <div className="flex justify-between items-center m-2">
          <div className="">
            <div className="flex  items-center">
              <FaBriefcase size={25} />
              <p className="font-bold ml-2 text-2xl">Jobs</p>
            </div>
            <p className="font-light text-sm mt-2  ml-2 ">
              {latest_count} new jobs added this week.
            </p>
          </div>
          <div>
            <div className="relative">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M17.7394 18.0779C17.8152 18.0465 17.8842 18.0004 17.9422 17.9422C18.0003 17.8842 18.0464 17.8153 18.0779 17.7394C18.1093 17.6635 18.1255 17.5822 18.1255 17.5001C18.1255 17.4179 18.1093 17.3366 18.0779 17.2607C18.0464 17.1848 18.0003 17.1159 17.9422 17.0579L14.0313 13.1469C15.164 11.7879 15.7291 10.0444 15.6092 8.27931C15.4893 6.51418 14.6935 4.86323 13.3873 3.66986C12.0812 2.4765 10.3653 1.83259 8.59657 1.87208C6.82781 1.91156 5.14236 2.6314 3.89079 3.88186C2.63923 5.13232 1.9179 6.81713 1.87685 8.58586C1.83581 10.3546 2.4782 12.071 3.67041 13.3782C4.86262 14.6854 6.51286 15.4826 8.27788 15.6041C10.0429 15.7256 11.7868 15.162 13.1469 14.0305L17.0578 17.9422C17.1159 18.0004 17.1848 18.0465 17.2607 18.0779C17.3366 18.1094 17.4179 18.1255 17.5 18.1255C17.5822 18.1255 17.6635 18.1094 17.7394 18.0779ZM11.8751 13.4271C10.9501 14.0452 9.86254 14.3751 8.75002 14.3751C7.25869 14.3734 5.82891 13.7802 4.77438 12.7257C3.71984 11.6712 3.12668 10.2414 3.12502 8.75005C3.12502 7.63753 3.45492 6.55 4.07301 5.62497C4.69109 4.69995 5.5696 3.97898 6.59743 3.55323C7.62526 3.12749 8.75626 3.0161 9.84741 3.23314C10.9386 3.45018 11.9408 3.98591 12.7275 4.77258C13.5142 5.55925 14.0499 6.56153 14.2669 7.65267C14.484 8.74381 14.3726 9.87481 13.9468 10.9026C13.5211 11.9305 12.8001 12.809 11.8751 13.4271Z"
                  fill="#667984"
                />
                <path
                  d="M13.125 8.75005C13.125 9.61535 12.8684 10.4612 12.3877 11.1807C11.907 11.9001 11.2237 12.4609 10.4243 12.792C9.62484 13.1232 8.74517 13.2098 7.89651 13.041C7.04784 12.8722 6.26829 12.4555 5.65643 11.8436C5.04458 11.2318 4.6279 10.4522 4.45909 9.60358C4.29028 8.75491 4.37692 7.87524 4.70805 7.07581C5.03919 6.27639 5.59994 5.59311 6.31941 5.11238C7.03887 4.63164 7.88473 4.37505 8.75003 4.37505C9.91035 4.37505 11.0231 4.83599 11.8436 5.65646C12.6641 6.47693 13.125 7.58973 13.125 8.75005Z"
                  fill="#CCD2D6"
                />
              </svg>
              <input
                className="pl-10 border-2 rounded-xl font-['Satoshi'] placeholder-gray-400  border-gray-400 "
                type="text"
                placeholder="Find a job Opening"
                onChange={handleSearch}
                onClick={handleClickMixpanel}
              />
            </div>
          </div>
        </div>

        {/* <div className="flex">
          <div className="w-1/4 h-screen overflow-x-scroll">
            {jobs.map((job: any) => (
              <div
                className={`m-2 rounded-lg p-4 ${
                  true ? "bg-[#B7EBD9]" : "bg-white"
                }`}
              >
                <div className="flex items-center h-18">
                  <img
                    src={url_prefix + job.company_info.logo}
                    className="h-8"
                  />
                  <div className="ml-2">
                    <p className="font-bold text-sm"> {job.job_profile} </p>
                    <p className="font-light text-xs">
                      {job.company_info.name}
                    </p>
                  </div>
                </div>
                <div className="flex items-center"></div>
              </div>
            ))}
          </div>
        </div> */}
        <div className="m-2 p-1 rounded bg-white">
          <Table>
            <Table.Head>
              <TableHeadCell>S.No</TableHeadCell>
              <TableHeadCell>Job Name</TableHeadCell>
              <TableHeadCell>Company</TableHeadCell>
              <TableHeadCell>Posted On</TableHeadCell>
              <TableHeadCell>Actions</TableHeadCell>
            </Table.Head>
            {jobs && (
              <Table.Body>
                {jobs.map((job: any, index: any) => (
                  <TableRow>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{job.job_profile}</TableCell>
                    <TableCell>
                      <div className="flex items-center">
                        <img
                          src={url_prefix + job.company_info.logo}
                          className="h-6"
                        />
                        <p className="ml-1">{job.company_info.name}</p>
                      </div>
                    </TableCell>
                    <TableCell>
                      {moment(job.created_at).format("MMMM Do YYYY")}
                    </TableCell>
                    <TableCell>
                      <div className="flex">
                        <Button
                          color="#FFDA46"
                          className="bg-[#FFDA46] text-black"
                          onClick={() => {
                            Mixpanel.track("link_clicked", {
                              date: moment().format(),
                              browser: browserName,
                              mobile_device: isMobileOnly,
                              distinct_id: localStorage.getItem("tpo_id"),
                              tpo_id: localStorage.getItem("tpo_id"),
                              job_id: job._id,
                              job_profile: job.job_profile,
                              company_name: job.company_info.name,
                            });
                            navigator.clipboard.writeText(job.link);
                            toast.success(
                              "Job Unique link copied to Clipboard."
                            );
                          }}
                        >
                          Link
                        </Button>
                        <Button
                          color="#FFDA46"
                          className="bg-[#FFDA46] text-black ml-1"
                          onClick={() => {
                            Mixpanel.track("job_details_clicked", {
                              date: moment().format(),
                              browser: browserName,
                              mobile_device: isMobileOnly,
                              distinct_id: localStorage.getItem("tpo_id"),
                              tpo_id: localStorage.getItem("tpo_id"),
                              job_id: job._id,
                              job_profile: job.job_profile,
                              company_name: job.company_info.name,
                            });
                            navigate(`/job_deatils/${job._id}`);
                          }}
                        >
                          Details
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </Table.Body>
            )}
            {searchJobs !== "" && (
              <Table.Body>
                {searchJobs.map((job: any, index: any) => (
                  <TableRow>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{job.job_profile}</TableCell>
                    <TableCell>
                      <div className="flex items-center">
                        <img
                          src={url_prefix + job.company_info.logo}
                          className="h-6"
                        />
                        <p className="ml-1">{job.company_info.name}</p>
                      </div>
                    </TableCell>
                    <TableCell>
                      {moment(job.created_at).format("MMMM Do YYYY")}
                    </TableCell>
                    <TableCell>
                      <div className="flex">
                        <Button
                          color="#FFDA46"
                          className="bg-[#FFDA46] text-black"
                          onClick={() => {
                            navigator.clipboard.writeText(job.link);
                            toast.success(
                              "Job Unique link copied to Clipboard."
                            );
                          }}
                        >
                          Link
                        </Button>
                        <Button
                          color="#FFDA46"
                          className="bg-[#FFDA46] text-black ml-1"
                          onClick={() => {
                            navigate(`/job_deatils/${job._id}`);
                          }}
                        >
                          Details
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </Table.Body>
            )}
          </Table>
          {has_next ? (
            <div className="flex mt-2 mb-2 justify-center">
              <Button
                onClick={() => {
                  get_jobs(jobs.length, 30);
                }}
                color="#FFDA46"
                className="bg-[#FFDA46] text-black"
              >
                Load More
              </Button>
            </div>
          ) : null}
        </div>
      </div>
      {loading ? <SpinnerComponent /> : null}
    </div>
  );
}

export default Jobs;
