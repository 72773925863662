import axios from "axios";
import SideNavbar from "../Components/SideNavabar";
import { FaBook } from "react-icons/fa6";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { browserName, isMobileOnly } from "react-device-detect";
import {
  Button,
  ModalBody,
  ModalHeader,
  Modal,
  FileInput,
  Label,
  ModalFooter,
  Accordion,
  AccordionPanel,
  Table,
  TableHead,
  TableHeadCell,
  TableBody,
  TableRow,
  TableCell,
} from "flowbite-react";
import { IoIosAddCircle } from "react-icons/io";
import { RiUploadCloud2Fill } from "react-icons/ri";
import { FaCheckCircle } from "react-icons/fa";
import { FaArrowsRotate } from "react-icons/fa6";
import moment from "moment";
import SpinnerComponent from "../Components/SpinnerComponent";
import Buttons from "../Components/Buttons";
import FilterBox from "../Components/DialogBox/filterBox";
import { Mixpanel } from "../Services/mixpanel";
import ViewStatus from "../Components/studentStatus/ViewStatus";

function Batches() {
  const [loading, set_loading] = useState(false);
  const [show_modal, set_show_modal] = useState(false);
  const [students, set_students]: any = useState([]);
  const [has_next, set_has_next] = useState(false);
  const [file, set_file]: any = useState([]);
  const [failed_upload, set_failed_upload] = useState("");
  const [success_count, set_success_count] = useState(0);
  const [failures, set_failures] = useState([]);
  const [warnings, set_warnings] = useState([]);
  const [upload_state, set_upload_state] = useState("new");
  const [filterBox, setFilterBox] = useState(false);
  const [filterStatus, setfilterStatus] = useState("");
  const [filterStudentsInvited, setFilterStudentsInvited]: any = useState([]);
  const [filterStudentsOnboarded, setFilterStudentsOnboarded]: any = useState(
    []
  );
  const [sortData, setSortData]: any = useState([]);
  const [sortDataDesc, setSortDataDesc]: any = useState([]);

  const [toggler, setToggeler]: any = useState(false);
  const [csvUrl, setCsvUrl]: any = useState("");
  const [sortOrderAsc, setSortOrderAsc]: any = useState(-1);
  const [sortToggle, setSortToggle]: any = useState(false);
  const [toggleViewStatus, setToggleViewStatus] = useState(false);
  const [candidateInfoId, setcandidateInfoId] = useState("");
  console.log("sortCheck", sortToggle, sortOrderAsc);
  const changeSortStatus = () => {
    setSortToggle(true);
    setSortOrderAsc(sortOrderAsc === 1 ? -1 : 1);
    handleSortClick(0, 30);
  };
  useEffect(() => {
    handleSortClick(0, 30);
  }, [sortToggle]);
  const BASE_SERVER_URL = `https://api.beep.community`;
  const handleSortClick = async (skip: number, limit: number) => {
    let toggleSort: string = `sortOrder=${sortOrderAsc}`;
    set_loading(true);
    await axios
      .post(
        `${BASE_SERVER_URL}/tpo/get_students?limit=${limit}&skip=${skip}&${toggleSort}`,
        {},
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("tpo_token"),
          },
        }
      )
      .then(({ data }) => {
        set_loading(false);
        if (sortOrderAsc === 1) {
          setSortData([...sortData, ...data.students]);
        } else if (sortOrderAsc === -1) {
          setSortDataDesc([...sortDataDesc, ...data.students]);
        }
      })
      .catch((err) => {
        set_loading(false);
        toast.error(
          `Failed to get jobs, Please try again ${
            err?.response?.data?.error ? err.response.data.error : ""
          }`
        );
      });
  };

  const get_students = async (skip: number, limit: number) => {
    const BASE_SERVER_URL = `https://api.beep.community`;

    const bodyParam: { [key: string]: any } = {};
    if (filterStatus !== "") {
      bodyParam.status = filterStatus;
    }

    set_loading(true);
    axios
      .post(
        `${BASE_SERVER_URL}/tpo/get_students?skip=${skip}&limit=${limit}`,
        bodyParam,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("tpo_token"),
          },
        }
      )
      .then(({ data }) => {
        set_loading(false);
        setSortToggle(false);
        if (filterStatus !== "" && filterStatus == "Invited") {
          setFilterStudentsInvited([
            ...filterStudentsInvited,
            ...data.students,
          ]);
        } else if (filterStatus !== "" && filterStatus == "Onboarded") {
          setFilterStudentsOnboarded([
            ...filterStudentsOnboarded,
            ...data.students,
          ]);
        } else {
          set_students([...students, ...data.students]);
          setFilterStudentsInvited([]);
          setFilterStudentsOnboarded([]);
        }
        set_has_next(data.has_next);
      })
      .catch((err) => {
        set_loading(false);
        toast.error(
          `Failed to get jobs, Please try again ${
            err?.response?.data?.error ? err.response.data.error : ""
          }`
        );
      });
  };
  const handleGetStudents = () => {
    get_students(0, 30);
  };

  /*Download CSV*/

  const handleDownloadCSVData = async () => {
    const BASE_SERVER_URL = `https://api.beep.community`;
    const url = `${BASE_SERVER_URL}/tpo/download`;

    const bodyParam: { [key: string]: any } = {};
    if (filterStatus !== "") {
      bodyParam.status = filterStatus;
    }
    set_loading(true);
    axios
      .post(`${url}`, bodyParam, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("tpo_token"),
        },
      })
      .then(({ data }) => {
        setCsvUrl(data?.url);
        const link = document.createElement("a");
        link.href = data?.url;
        link.setAttribute("download", "students.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        set_loading(false);
      })
      .catch((err) => {
        set_loading(false);
        toast.error(
          `Failed to get download csv url, Please try again ${
            err?.response?.data?.error ? err.response.data.error : ""
          }`
        );
      });
  };

  const submit_file = async () => {
    set_loading(true);
    set_failed_upload("");
    set_success_count(0);
    set_failures([]);
    set_warnings([]);
    set_upload_state("uploading");
    let form_data = new FormData();
    console.log(file[0]);
    form_data.append("upload", file[0]);
    axios
      .post(`https://api.beep.community/tpo/students`, form_data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + localStorage.getItem("tpo_token"),
        },
      })
      .then(({ data }) => {
        set_success_count(data.valid_entries);
        set_failures(data.invalid_entries);
        set_warnings(data.warnings);
        set_upload_state("completed");
        set_loading(false);
      })
      .catch((err) => {
        set_failed_upload(
          err?.response?.data?.error ? err.response.data.error : ""
        );
        set_upload_state("completed");
        set_loading(false);
      });
  };

  useEffect(() => {
    get_students(0, 30);
  }, []);

  const download_template = async () => {
    axios({
      url: "https://test12345647872448cc6099.blob.core.windows.net/test12345647872448cc6099/template.csv", //your url
      method: "GET",
      responseType: "blob", // important
    })
      .then((response) => {
        // create file link in browser's memory
        const href = URL.createObjectURL(response.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", "template.csv"); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      })
      .catch((err) => {
        toast.error("Failed to download template, please try agian.");
      });
  };

  const HandleViewStatus = () => {};

  return (
    <div className="flex">
      <div className="w-1/6">
        <SideNavbar page_name="Batches" />
      </div>
      <div className="w-5/6 p-4 h-screen overflow-x-scroll bg-[#F2F4F5]">
        <div className="flex justify-between relative">
          <div className="flex m-2 items-center">
            <FaBook size={25} />
            <p className="font-bold ml-2 text-2xl">Students</p>
          </div>
          <div className=" inline-flex gap-8 items-center ">
            <Buttons
              className={""}
              onClick={() => setFilterBox(!filterBox)}
              leftIcon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M2.5 6.25C2.5 6.08423 2.56585 5.92526 2.68306 5.80805C2.80027 5.69084 2.95924 5.625 3.125 5.625H6.02891C6.16362 5.17372 6.44038 4.77798 6.81803 4.4966C7.19568 4.21522 7.65405 4.06323 8.125 4.06323C8.59595 4.06323 9.05432 4.21522 9.43197 4.4966C9.80962 4.77798 10.0864 5.17372 10.2211 5.625H16.875C17.0408 5.625 17.1997 5.69084 17.3169 5.80805C17.4342 5.92526 17.5 6.08423 17.5 6.25C17.5 6.41576 17.4342 6.57473 17.3169 6.69194C17.1997 6.80915 17.0408 6.875 16.875 6.875H10.2211C10.0864 7.32627 9.80962 7.72201 9.43197 8.00339C9.05432 8.28477 8.59595 8.43676 8.125 8.43676C7.65405 8.43676 7.19568 8.28477 6.81803 8.00339C6.44038 7.72201 6.16362 7.32627 6.02891 6.875H3.125C2.95924 6.875 2.80027 6.80915 2.68306 6.69194C2.56585 6.57473 2.5 6.41576 2.5 6.25ZM16.875 13.125H15.2211C15.0864 12.6737 14.8096 12.278 14.432 11.9966C14.0543 11.7152 13.5959 11.5632 13.125 11.5632C12.6541 11.5632 12.1957 11.7152 11.818 11.9966C11.4404 12.278 11.1636 12.6737 11.0289 13.125H3.125C2.95924 13.125 2.80027 13.1908 2.68306 13.3081C2.56585 13.4253 2.5 13.5842 2.5 13.75C2.5 13.9158 2.56585 14.0747 2.68306 14.1919C2.80027 14.3091 2.95924 14.375 3.125 14.375H11.0289C11.1636 14.8263 11.4404 15.222 11.818 15.5034C12.1957 15.7848 12.6541 15.9368 13.125 15.9368C13.5959 15.9368 14.0543 15.7848 14.432 15.5034C14.8096 15.222 15.0864 14.8263 15.2211 14.375H16.875C17.0408 14.375 17.1997 14.3091 17.3169 14.1919C17.4342 14.0747 17.5 13.9158 17.5 13.75C17.5 13.5842 17.4342 13.4253 17.3169 13.3081C17.1997 13.1908 17.0408 13.125 16.875 13.125Z"
                    fill="#667984"
                  />
                </svg>
              }
              text={"Filter"}
            />

            <Buttons
              className={""}
              onClick={handleDownloadCSVData}
              leftIcon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M16.5673 11.2004C16.3639 11.2004 16.1689 11.2812 16.0251 11.425C15.8814 11.5687 15.8006 11.7637 15.8006 11.9671V15.0339C15.8006 15.2372 15.7198 15.4322 15.576 15.576C15.4322 15.7198 15.2372 15.8006 15.0339 15.8006H4.3001C4.09676 15.8006 3.90174 15.7198 3.75796 15.576C3.61418 15.4322 3.5334 15.2372 3.5334 15.0339V11.9671C3.5334 11.7637 3.45262 11.5687 3.30884 11.425C3.16505 11.2812 2.97004 11.2004 2.7667 11.2004C2.56336 11.2004 2.36834 11.2812 2.22456 11.425C2.08078 11.5687 2 11.7637 2 11.9671V15.0339C2 15.6439 2.24233 16.2289 2.67368 16.6603C3.10504 17.0917 3.69007 17.334 4.3001 17.334H15.0339C15.6439 17.334 16.2289 17.0917 16.6603 16.6603C17.0917 16.2289 17.334 15.6439 17.334 15.0339V11.9671C17.334 11.7637 17.2532 11.5687 17.1094 11.425C16.9656 11.2812 16.7706 11.2004 16.5673 11.2004ZM9.12264 12.5114C9.19555 12.5812 9.28153 12.636 9.37565 12.6725C9.46742 12.713 9.56665 12.734 9.66699 12.734C9.76733 12.734 9.86656 12.713 9.95834 12.6725C10.0525 12.636 10.1384 12.5812 10.2113 12.5114L13.2781 9.44465C13.4225 9.30028 13.5036 9.10447 13.5036 8.90029C13.5036 8.69612 13.4225 8.50031 13.2781 8.35594C13.1338 8.21156 12.938 8.13046 12.7338 8.13046C12.5296 8.13046 12.3338 8.21156 12.1894 8.35594L10.4337 10.1193V2.7667C10.4337 2.56336 10.3529 2.36834 10.2091 2.22456C10.0653 2.08078 9.87033 2 9.66699 2C9.46365 2 9.26864 2.08078 9.12485 2.22456C8.98107 2.36834 8.90029 2.56336 8.90029 2.7667V10.1193L7.14455 8.35594C7.07307 8.28445 6.9882 8.22774 6.8948 8.18906C6.8014 8.15037 6.70129 8.13046 6.60019 8.13046C6.4991 8.13046 6.39899 8.15037 6.30559 8.18906C6.21219 8.22774 6.12732 8.28445 6.05584 8.35594C5.98435 8.42742 5.92765 8.51229 5.88896 8.60569C5.85027 8.69909 5.83036 8.7992 5.83036 8.90029C5.83036 9.00139 5.85027 9.1015 5.88896 9.1949C5.92765 9.2883 5.98435 9.37316 6.05584 9.44465L9.12264 12.5114Z"
                    fill="#667984"
                  />
                </svg>
              }
              text={"Download"}
            />
            {filterBox && (
              <FilterBox
                cancel={() => {
                  setFilterBox(false);
                  setfilterStatus("");
                }}
                setfilterStatus={setfilterStatus}
                handleGetStudents={handleGetStudents}
                filterStatus={filterStatus}
                setToggeler={setToggeler}
                setFilterBox={setFilterBox}
              />
            )}

            <Button
              color="#002032"
              className="flex bg-[#002032] text-white"
              onClick={() => {
                set_show_modal(true);
                Mixpanel.track("add_students_clicked", {
                  date: moment().format(),
                  browser: browserName,
                  mobile_device: isMobileOnly,
                  distinct_id: localStorage.getItem("tpo_id"),
                  tpo_id: localStorage.getItem("tpo_id"),
                  campus_alias: localStorage.getItem("campus_alias"),
                  campus_name: localStorage.getItem("campus_name"),
                  campus_city: localStorage.getItem("campus_city"),
                });
              }}
            >
              <IoIosAddCircle size={14} />
              <p className="ml-2 font-bold text-sm">Add Students</p>
            </Button>
          </div>
        </div>
        <div className="m-2 p-1 rounded bg-white relative">
          <Table>
            <TableHead>
              <TableHeadCell>S.No</TableHeadCell>
              <TableHeadCell>Name</TableHeadCell>
              <TableHeadCell>Number</TableHeadCell>
              <TableHeadCell>Email</TableHeadCell>
              <TableHeadCell className="flex gap-2">
                Grad Year{" "}
                <svg
                  onClick={changeSortStatus}
                  className="cursor-pointer"
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M5.63695 7.12794C5.61289 7.2492 5.62534 7.37488 5.67271 7.48907C5.71999 7.60328 5.80008 7.70091 5.90285 7.7696C6.00561 7.8383 6.12644 7.87498 6.25005 7.875H13.7501C13.8737 7.8751 13.9947 7.8385 14.0975 7.76983C14.2004 7.70116 14.2806 7.60351 14.3279 7.48925C14.3753 7.37498 14.3877 7.24924 14.3635 7.12794C14.3394 7.00663 14.2797 6.89523 14.1922 6.80782L10.4422 3.05782C10.3842 2.99971 10.3153 2.95361 10.2394 2.92215C10.1635 2.8907 10.0822 2.87451 10.0001 2.87451C9.91792 2.87451 9.83659 2.8907 9.76072 2.92215C9.68484 2.95361 9.61591 2.99971 9.55787 3.05782L5.80787 6.80782C5.72049 6.89527 5.66101 7.00668 5.63695 7.12794Z"
                    fill="#667984"
                  />
                  <path
                    d="M13.7505 12.125H6.25049C6.12681 12.1249 6.00587 12.1615 5.903 12.2302C5.80013 12.2988 5.71995 12.3965 5.6726 12.5108C5.62526 12.625 5.61288 12.7508 5.63704 12.8721C5.66119 12.9934 5.7208 13.1048 5.8083 13.1922L9.5583 16.9422C9.61635 17.0003 9.68528 17.0464 9.76115 17.0779C9.83703 17.1093 9.91836 17.1255 10.0005 17.1255C10.0826 17.1255 10.164 17.1093 10.2398 17.0779C10.3157 17.0464 10.3846 17.0003 10.4427 16.9422L14.1927 13.1922C14.2802 13.1048 14.3398 12.9934 14.3639 12.8721C14.3881 12.7508 14.3757 12.625 14.3284 12.5108C14.281 12.3965 14.2009 12.2988 14.098 12.2302C13.9951 12.1615 13.8742 12.1249 13.7505 12.125Z"
                    fill="#CCD2D6"
                  />
                </svg>
              </TableHeadCell>
              <TableHeadCell>Added On</TableHeadCell>
              <TableHeadCell>Status</TableHeadCell>
              <TableHeadCell>Application Status</TableHeadCell>
            </TableHead>

            {filterStatus !== "" && filterStatus === "Invited" && toggler ? (
              <TableBody>
                {filterStudentsInvited.map((student: any, index: number) => (
                  <TableRow>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{student.profileInfo.name}</TableCell>
                    <TableCell>{student.number}</TableCell>
                    <TableCell>{student.email}</TableCell>
                    <TableCell>{student.grad_year}</TableCell>
                    <TableCell>
                      {moment(student.invited_on).format("MMMM Do YYYY")}
                    </TableCell>
                    <TableCell>{student.status}</TableCell>
                    <TableCell
                      onClick={() => {
                        setToggleViewStatus(true);
                        setcandidateInfoId(student?._id);
                      }}
                      className="cursor-pointer hover:font-semibold text-gray-500"
                    >
                      <Button color="gray">View Status</Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            ) : filterStatus !== "" &&
              filterStatus === "Onboarded" &&
              toggler ? (
              <TableBody>
                {filterStudentsOnboarded.map((student: any, index: number) => (
                  <TableRow>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{student.profileInfo.name}</TableCell>
                    <TableCell>{student.number}</TableCell>
                    <TableCell>{student.email}</TableCell>
                    <TableCell>{student.grad_year}</TableCell>
                    <TableCell>
                      {moment(student.invited_on).format("MMMM Do YYYY")}
                    </TableCell>
                    <TableCell>{student.status}</TableCell>
                    <TableCell
                      onClick={() => {
                        setToggleViewStatus(true);
                        setcandidateInfoId(student?._id);
                      }}
                      className="cursor-pointer hover:font-semibold text-gray-500"
                    >
                      <Button color="gray">View Status</Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            ) : sortToggle && sortOrderAsc === 1 ? (
              <TableBody>
                {sortData.map((student: any, index: number) => (
                  <TableRow>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{student.profileInfo.name}</TableCell>
                    <TableCell>{student.number}</TableCell>
                    <TableCell>{student.email}</TableCell>
                    <TableCell>{student.grad_year}</TableCell>
                    <TableCell>
                      {moment(student.invited_on).format("MMMM Do YYYY")}
                    </TableCell>
                    <TableCell>{student.status}</TableCell>
                    <TableCell
                      onClick={() => {
                        setToggleViewStatus(true);
                        setcandidateInfoId(student?._id);
                      }}
                      className="cursor-pointer hover:font-semibold text-gray-500"
                    >
                      <Button color="gray">View Status</Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            ) : sortToggle && sortOrderAsc === -1 ? (
              <TableBody>
                {sortDataDesc.map((student: any, index: number) => (
                  <TableRow>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{student.profileInfo.name}</TableCell>
                    <TableCell>{student.number}</TableCell>
                    <TableCell>{student.email}</TableCell>
                    <TableCell>{student.grad_year}</TableCell>
                    <TableCell>
                      {moment(student.invited_on).format("MMMM Do YYYY")}
                    </TableCell>
                    <TableCell>{student.status}</TableCell>
                    <TableCell
                      onClick={() => {
                        setToggleViewStatus(true);
                        setcandidateInfoId(student?._id);
                      }}
                      className="cursor-pointer hover:font-semibold text-gray-500"
                    >
                      <Button color="gray">View Status</Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            ) : (
              <TableBody>
                {students.map((student: any, index: number) => (
                  <TableRow>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{student.profileInfo.name}</TableCell>
                    <TableCell>{student.number}</TableCell>
                    <TableCell>{student.email}</TableCell>
                    <TableCell>{student.grad_year}</TableCell>
                    <TableCell>
                      {moment(student.invited_on).format("MMMM Do YYYY")}
                    </TableCell>
                    <TableCell>{student.status}</TableCell>
                    <TableCell
                      onClick={() => {
                        setToggleViewStatus(true);
                        setcandidateInfoId(student?._id);
                      }}
                      className="cursor-pointer hover:font-semibold text-gray-500"
                    >
                      <Button color="gray">View Status</Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            )}
          </Table>
          {has_next ? (
            <div className="flex mt-2 mb-2 justify-center">
              <Button
                onClick={() => {
                  sortToggle
                    ? handleSortClick(sortData.length, 30)
                    : get_students(students.length, 30);
                }}
                color="#FFDA46"
                className="bg-[#FFDA46] text-black"
              >
                Load More
              </Button>
            </div>
          ) : null}
        </div>
        {toggleViewStatus && (
          <ViewStatus
            candidateInfoId={candidateInfoId}
            onClose={() => setToggleViewStatus(false)}
          />
        )}
      </div>
      <Modal
        show={show_modal}
        onClose={() => {
          set_upload_state("new");
          set_show_modal(false);
        }}
      >
        <ModalHeader>Add Students</ModalHeader>
        <ModalBody>
          {upload_state === "new" ? (
            <div className="h-64 flex rounded-lg bg-[#CCD2D6]">
              <div className="w-1/2 flex flex-col border-r-2 justify-center content-center items-center text-left">
                <p className="font-light text-sm text-[#667984] mt-1 text-center w-2/3">
                  Upload CSV should have following columns:
                </p>
                <ul className="list-disc font-light text-sm text-[#667984] mt-2">
                  <li>Name</li>
                  <li>Number</li>
                  <li>Email</li>
                  <li>Grad Year</li>
                </ul>
              </div>
              <div className="w-1/2 flex flex-col justify-center items-center content-center">
                <div className="p-4">
                  <Label htmlFor="file" value="Upload file" />
                  <FileInput
                    id="file"
                    placeholder="Student's CSV File"
                    accept=".csv"
                    onChange={(event) => {
                      console.log(event);
                      set_file(event.target.files);
                    }}
                  />
                </div>
                <Button
                  color="#16C083"
                  className="flex p-1 h-12 text-white bg-[#16C083] mt-2"
                  disabled={file.length < 1}
                  onClick={submit_file}
                >
                  <RiUploadCloud2Fill />
                  <p className="ml-2">Upload</p>
                </Button>
                <p className="font-light text-xs text-[#667984] mt-1 w-1/2 text-center">
                  Upload the CSV to add Students
                </p>
                <p
                  className="mt-6 text-xs text-[#667984] cursor-pointer"
                  onClick={() => {
                    download_template();
                  }}
                >
                  Click here to download the template
                </p>
              </div>
            </div>
          ) : null}
          {upload_state === "uploading" ? (
            <div className="h-64 flex flex-col rounded-lg bg-[#CCD2D6] justify-center items-center content-center">
              <div className="flex items-center justify-center text-[#008000] font-bold">
                <FaCheckCircle />
                <p className="ml-2">File sent to server</p>
              </div>
              <div className="flex mt-1 mb-1">
                <div className="w-1/2 h-6 border-dashed border-r-2 border-[#008000]">
                  <p></p>
                </div>
                <div className="w-1/2"></div>
              </div>
              <div className="flex items-center justify-center text-[#002032] font-bold">
                <FaArrowsRotate />
                <p className="ml-2">Processing File</p>
              </div>
              <div className="flex mt-1 mb-1">
                <div className="w-1/2 h-6 border-dashed border-r-2 border-[#002032]">
                  <p></p>
                </div>
                <div className="w-1/2"></div>
              </div>
              <div className="flex items-center justify-center text-[#002032] font-extralight text-sm">
                <FaArrowsRotate />
                <p className="ml-2">Processing Completed</p>
              </div>
            </div>
          ) : null}
          {upload_state === "completed" ? (
            <div className="h-48 flex flex-col rounded-lg bg-[#CCD2D6] justify-center items-center content-center">
              <div className="flex items-center justify-center text-[#008000] font-bold">
                <FaCheckCircle />
                <p className="ml-2">File sent to server</p>
              </div>
              <div className="flex mt-1 mb-1">
                <div className="w-1/2 h-6 border-dashed border-r-2 border-[#008000]">
                  <p></p>
                </div>
                <div className="w-1/2"></div>
              </div>
              <div className="flex items-center justify-center text-[#008000] font-bold">
                <FaCheckCircle />
                <p className="ml-2">Processing File</p>
              </div>
              <div className="flex mt-1 mb-1">
                <div className="w-1/2 h-6 border-dashed border-r-2 border-[#008000]">
                  <p></p>
                </div>
                <div className="w-1/2"></div>
              </div>
              <div className="flex items-center justify-center text-[#008000] font-bold">
                <FaArrowsRotate />
                <p className="ml-2">Processing Completed</p>
              </div>
            </div>
          ) : null}
        </ModalBody>
        {upload_state === "completed" ? (
          <ModalFooter className="h-1/4 overflow-x-scroll flex flex-col">
            {failed_upload.length > 0 ? (
              <div className="bg-red-700 text-white text-sm mt-1 mb-1 mr-3 ml-3 w-full rounded p-1">
                Failed to upload file: {failed_upload}
              </div>
            ) : null}
            {success_count > 0 ? (
              <div className="bg-green-700 text-white text-sm mt-1 mb-1 mr-3 ml-3 w-full rounded p-1">
                {success_count} entries created successfully
              </div>
            ) : null}
            {failures.length > 0 ? (
              <Accordion className="w-full" collapseAll>
                <AccordionPanel className="p-1">
                  <Accordion.Title
                    color="red"
                    className="bg-red-700 hover:bg-red-700 hover:text-white text-white text-sm rounded p-1"
                  >
                    {failures.length} entries Failed
                  </Accordion.Title>
                  <Accordion.Content>
                    <div>
                      <Table>
                        <TableHead>
                          <TableHeadCell>Row</TableHeadCell>
                          <TableHeadCell>Error</TableHeadCell>
                        </TableHead>
                        <TableBody>
                          {failures.map((failed) => (
                            <TableRow>
                              <TableCell>{failed[0]}</TableCell>
                              <TableCell>{failed[1]}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </div>
                  </Accordion.Content>
                </AccordionPanel>
              </Accordion>
            ) : null}
            {warnings.length > 0 ? (
              <Accordion className="w-full" collapseAll>
                <AccordionPanel className="p-1">
                  <Accordion.Title className="bg-yellow-500 text-white hover:bg-yellow-500 hover:text-white text-sm rounded p-1">
                    {warnings.length} entries created with warning
                  </Accordion.Title>
                  <Accordion.Content>
                    <div>
                      <Table>
                        <TableHead>
                          <TableHeadCell>Row</TableHeadCell>
                          <TableHeadCell>Warning</TableHeadCell>
                        </TableHead>
                        <TableBody>
                          {warnings.map((failed) => (
                            <TableRow>
                              <TableCell>{failed[0]}</TableCell>
                              <TableCell>{failed[1]}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </div>
                  </Accordion.Content>
                </AccordionPanel>
              </Accordion>
            ) : null}
          </ModalFooter>
        ) : null}
      </Modal>
      {loading ? <SpinnerComponent /> : null}
    </div>
  );
}

export default Batches;
