import React from "react";
interface ButtonProps {
  text: string;
  leftIcon: any;
  className : any
  onClick : any
}
function Buttons({ text, leftIcon ,className,onClick}: ButtonProps) {
  return (
    <button className={`border-gray-200 border-2 bg-white py-3 px-3 rounded-2xl inline-flex gap-2 items-center text-center text-slate-500 text-sm font-bold ${className}`} onClick={onClick}>
      <span>{leftIcon}</span>
      {text}
    </button>
  );
}

export default Buttons;
