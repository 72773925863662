import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import SideNavbar from "../Components/SideNavabar";
import { MdDescription } from "react-icons/md";
import { Badge, Button } from "flowbite-react";
import { FaShareNodes } from "react-icons/fa6";
import moment from "moment";
import { FaLocationDot } from "react-icons/fa6";
import { IoTimeSharp } from "react-icons/io5";
import parse from "html-react-parser";
import SpinnerComponent from "../Components/SpinnerComponent";
import { Mixpanel } from "../Services/mixpanel";
import { browserName, isMobileOnly } from "react-device-detect";

function JobDetails() {
  const { id } = useParams();
  const [loading, set_loading] = useState(false);
  const [job_details, set_job_details]: any = useState({});

  const get_job_details = async () => {
    set_loading(true);
    axios
      .get(`https://api.beep.community/tpo/job_details/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tpo_token"),
        },
      })
      .then(({ data }) => {
        set_loading(false);
        set_job_details(data);
      })
      .catch((err) => {
        set_loading(false);
        toast.error(
          `Failed to get jobs, Please try again ${
            err?.response?.data?.error ? err.response.data.error : ""
          }`
        );
      });
  };

  useEffect(() => {
    get_job_details();
  }, []);

  return (
    <div className="flex">
      <div className="w-1/6">
        <SideNavbar page_name="Jobs" />
      </div>
      <div className="w-5/6 p-4 h-screen overflow-x-scroll bg-[#F2F4F5]">
        <div className="flex m-2 items-center">
          <MdDescription size={25} />
          <p className="font-bold ml-2 text-2xl">Job Details</p>
        </div>
        <div className="m-2 p-4 rounded bg-white">
          {Object.keys(job_details).length > 0 ? (
            <div>
              <div className="flex justify-between items-center">
                <div className="flex items-center">
                  <img
                    src={`${job_details.url_prefix}${job_details.company_info.logo}`}
                    className="h-12"
                  />
                  <div className="ml-2">
                    <p className="font-bold text-xl">
                      {" "}
                      {job_details.job_profile}{" "}
                    </p>
                    <p className="font-light text-xs">
                      {" "}
                      {job_details.company_info.name} {`\u2022`}{" "}
                      {moment().diff(moment(job_details.created_at), "months") <
                      1 ? (
                        <>
                          {moment().diff(
                            moment(job_details.created_at),
                            "days"
                          ) < 1 ? (
                            <>
                              {moment().diff(
                                moment(job_details.created_at),
                                "hours"
                              ) < 1 ? (
                                <>
                                  {moment().diff(
                                    moment(job_details.created_at),
                                    "minutes"
                                  )}{" "}
                                  Minutes
                                </>
                              ) : (
                                <>
                                  {moment().diff(
                                    moment(job_details.created_at),
                                    "hours"
                                  )}{" "}
                                  Hours
                                </>
                              )}
                            </>
                          ) : (
                            <>
                              {moment().diff(
                                moment(job_details.created_at),
                                "days"
                              )}{" "}
                              Days
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          {moment().diff(
                            moment(job_details.created_at),
                            "months"
                          )}{" "}
                          Months
                        </>
                      )}
                    </p>
                  </div>
                </div>
                <div>
                  <Button
                    className="flex bg-[#16C083] text-white"
                    color="#16C083"
                    onClick={() => {
                      Mixpanel.track("share_clicked", {
                        date: moment().format(),
                        browser: browserName,
                        mobile_device: isMobileOnly,
                        distinct_id: localStorage.getItem("tpo_id"),
                        tpo_id: localStorage.getItem("tpo_id"),
                        job_id: job_details._id,
                        job_type: job_details.job_type,
                        job_profile: job_details.job_profile,
                        job_location: job_details.location.join(","),
                        campus_alias: localStorage.getItem("campus_alias"),
                        campus_name: localStorage.getItem("campus_name"),
                        campus_city: localStorage.getItem("campus_city"),
                      });
                      navigator.clipboard.writeText(job_details.link);
                      toast.success("Job Unique link copied to Clipboard.");
                    }}
                  >
                    <FaShareNodes />
                    <p className="ml-2">Share</p>
                  </Button>
                </div>
              </div>
              <div className="mt-4">
                <div className="flex items-center text-sm text-[#667984]">
                  <FaLocationDot />
                  <p className="ml-1">
                    {job_details.workplace_type}{" "}
                    {job_details.location?.length > 0 ? (
                      <>{job_details.location.join(", ")}</>
                    ) : null}
                  </p>
                </div>
                <div className="flex items-center text-sm text-[#667984]">
                  <IoTimeSharp />
                  <p className="ml-1">{job_details.job_type}</p>
                </div>
              </div>
              <div className="mt-4">
                <p className="font-bold text-md underline">Job Description</p>
                <div className="text-xs p-3 bg-[#F9F9FA] rounded-lg m-2">
                  {parse(job_details.job_description)}
                </div>
              </div>
              {job_details.perks?.length > 0 ? (
                <div className="mt-4">
                  <p className="font-bold text-sm">Perks</p>
                  <div className="text-xs p-3 flex gap-1">
                    {job_details.perks.map((perk: any) => (
                      <Badge color="gray">{perk}</Badge>
                    ))}
                  </div>
                </div>
              ) : null}
            </div>
          ) : null}
        </div>
      </div>
      {loading ? <SpinnerComponent /> : null}
    </div>
  );
}

export default JobDetails;
